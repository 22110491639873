window.addEventListener('DOMContentLoaded', (event) => {
  const pricingNowBtn = document.querySelector("#pricing-now");
  pricingNowBtn?.addEventListener("click", () => {
    gtag("event", "click", { event_category: "navigation", event_label: "pricing-now" });
  });

  const buyNowBtns = {
    basic: document.querySelector("#buy-basic"),
    premium: document.querySelector("#buy-premium"),
    vip: document.querySelector("#buy-vip")
  }
  
  Object.keys(buyNowBtns).forEach((plan) => {
    buyNowBtns[plan]?.addEventListener("click", () => {
      gtag("event", "click", { event_category: "navigation", event_label: `buy-${plan}` });
    });
  })
});
