
function setupMobileMenu() {
  const mobileMenu = {
    self: document.querySelector("#mobile-menu"),
    btns: [document.querySelector("#mobile-menu-open-btn"), document.querySelector("#mobile-menu-close-btn")]
  };

  mobileMenu.btns.forEach((btn) => {
    if (btn) {
      btn.addEventListener("click", toggleMobileMenu.bind(mobileMenu));
    }
  });

  function toggleMobileMenu() {
    if (this.self) {
      this.self.classList.toggle("hidden");
      this.btns.forEach((btn) => btn.classList.toggle("hidden"));
    }
  }
}

function setupFloatingBanners() {
  const banner = document.querySelector(".floating-banner");
  const delay = 10000;

  if (!banner) { return; }

  banner.querySelector(".floating-banner-close-btn").addEventListener("click", (e) => {
    const path = e.path || (e.composedPath && e.composedPath()) || composedPath(e.target);

    const banner = path.find((el) => el.classList.contains("floating-banner"));
    banner.style.bottom = "-100%";
    banner.style.opacity = "0";
  });

  setTimeout(() => {
    banner.style.bottom = "0px";
    banner.style.opacity = "1";
  }, delay);
}

function composedPath(el) {
    var path = [];

    while (el) {
        path.push(el);

        if (el.tagName === 'HTML') {
            path.push(document);
            path.push(window);

            return path;
       }

       el = el.parentElement;
    }
}

window.addEventListener("load", () => {
  setupMobileMenu();
  setupFloatingBanners();
});
